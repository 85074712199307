import {ScrollArea, Table, type TableProps} from '@mantine/core';

import type {Activity, Prisma} from '@rockawayxlabs/observatory-database';

import {convertCamelCaseToTitleCase} from '~/utils/format';

interface NewsfeedChangelogProps extends TableProps {
  before?: NonNullable<Activity['changeBefore']>;
  after?: NonNullable<Activity['changeAfter']>;
}

export function NewsfeedChangelog({before = {}, after = {}, style, ...others}: NewsfeedChangelogProps) {
  if (typeof after !== 'object' || Array.isArray(after) || typeof before !== 'object' || Array.isArray(before)) {
    return null;
  }

  const keys = [...new Set([...Object.keys(before), ...Object.keys(after)])];

  const formatValue = (value: Prisma.JsonValue | undefined) => (typeof value === 'object' ? '-' : value);

  if (keys.length <= 0) {
    return null;
  }

  return (
    <Table.ScrollContainer minWidth="100%">
      <Table
        withTableBorder
        withRowBorders
        withColumnBorders
        style={[{'--table-border-color': 'var(--observatory-color-border)', tableLayout: 'fixed' as const}, style]}
        {...others}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th fw={600} w={100} />
            <Table.Th fw={600} w={150} fs="sm">
              Before
            </Table.Th>
            <Table.Th fw={600} w={150} fs="sm">
              After
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {keys.map(key => (
            <Table.Tr key={key}>
              <Table.Td fs="sm" fw={600}>
                {convertCamelCaseToTitleCase(key)}
              </Table.Td>
              <Table.Td fs="sm">
                <ScrollArea type="always">{formatValue(before[key])}</ScrollArea>
              </Table.Td>
              <Table.Td fs="sm">
                <ScrollArea type="always">{formatValue(after[key])}</ScrollArea>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
