import type {Icon} from '@tabler/icons-react';
import {
  IconAbacus,
  IconAbacusOff,
  IconBan,
  IconBook,
  IconCloud,
  IconCloudOff,
  IconCoins,
  IconConfetti,
  IconLock,
  IconLockOff,
  IconPercentage,
  IconPlayerSkipForward,
  IconSend,
  IconServer,
  IconServerBolt,
  IconServerCog,
  IconServerOff,
  IconTimelineEvent,
} from '@tabler/icons-react';

import type {ActivityType} from '@rockawayxlabs/observatory-database';

const dictionary: Record<ActivityType, Icon> = {
  CHAIN_SLOWDOWN: IconPlayerSkipForward,
  NODE_ADDED: IconServer,
  NODE_CHANGED: IconServerCog,
  GOVERNANCE_VOTED: IconSend,
  GOVERNANCE_PROPOSAL_VOTING_START: IconAbacus,
  GOVERNANCE_PROPOSAL_VOTING_END: IconAbacusOff,
  VALIDATOR_OUTAGE: IconServerOff,
  VALIDATOR_OUTAGE_START: IconServerOff,
  VALIDATOR_OUTAGE_END: IconServerBolt,
  VALIDATOR_ACTIVE_START: IconCloud,
  VALIDATOR_ACTIVE_END: IconCloudOff,
  VALIDATOR_CREATED: IconConfetti,
  VALIDATOR_COMMISSION_CHANGE: IconPercentage,
  VALIDATOR_DESCRIPTION_CHANGE: IconBook,
  VALIDATOR_TOKENS_CHANGE: IconCoins,
  VALIDATOR_JAIL_START: IconLock,
  VALIDATOR_JAIL_END: IconLockOff,
  VALIDATOR_TOMBSTONED: IconBan,
};

export function getActivityIcon(type: ActivityType) {
  return dictionary[type] ?? IconTimelineEvent;
}
