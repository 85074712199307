import {Anchor} from '@mantine/core';
import {useParams} from '@remix-run/react';
import {useMemo} from 'react';

import {LinkBuilder} from '@rockawayxlabs/observatory-utils';

import {Link} from '~/features/links';
import type {NewsfeedActivityValidator} from '~/features/newsfeed/types';
import {formatValidatorMoniker} from '~/utils/validator';

interface Props {
  validator?: NewsfeedActivityValidator;
}

export function NewsfeedValidator({validator}: Props) {
  const {zoneSlug} = useParams<{zoneSlug: string}>();

  const {moniker, operatorAddress} = validator ?? {};
  const formattedMoniker = formatValidatorMoniker(moniker, operatorAddress ?? 'Some validator');

  const validatorUrl = useMemo(
    () =>
      zoneSlug && operatorAddress
        ? LinkBuilder.zone(zoneSlug).validators().detail(operatorAddress).toString()
        : undefined,
    [operatorAddress, zoneSlug]
  );

  if (validatorUrl) {
    return (
      <Anchor component={Link} to={validatorUrl} style={{wordBreak: 'break-all'}}>
        {formattedMoniker}
      </Anchor>
    );
  }

  return <span>{formattedMoniker}</span>;
}
