import {ActivityType} from '@rockawayxlabs/observatory-database';
import type {FeatureFlags} from '@rockawayxlabs/observatory-utils';

interface Params {
  featureFlags?: FeatureFlags;
  validatorOnly?: boolean;
}

export function getAllowedActivityTypes({featureFlags, validatorOnly}: Params = {}) {
  return [
    ...(!validatorOnly
      ? [
          ActivityType.CHAIN_SLOWDOWN,
          ActivityType.GOVERNANCE_PROPOSAL_VOTING_START,
          ActivityType.GOVERNANCE_PROPOSAL_VOTING_END,
        ]
      : []),
    ActivityType.GOVERNANCE_VOTED,
    ActivityType.VALIDATOR_ACTIVE_END,
    ActivityType.VALIDATOR_ACTIVE_START,
    ActivityType.VALIDATOR_COMMISSION_CHANGE,
    ActivityType.VALIDATOR_CREATED,
    ActivityType.VALIDATOR_DESCRIPTION_CHANGE,
    ActivityType.VALIDATOR_JAIL_END,
    ActivityType.VALIDATOR_JAIL_START,
    ...(featureFlags?.validatorOutages ? [ActivityType.VALIDATOR_OUTAGE_END, ActivityType.VALIDATOR_OUTAGE_START] : []),
    ActivityType.VALIDATOR_TOKENS_CHANGE,
    ActivityType.VALIDATOR_TOMBSTONED,
  ];
}
