import type {Prisma} from '@rockawayxlabs/observatory-database';

import {NumberFormat} from '~/utils/NumberFormat';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function ValidatorCommissionChange({changeAfter, changeBefore, validator}: ActivityTitleProps) {
  const formatCommission = (commission: Prisma.JsonValue) =>
    typeof commission === 'number' || typeof commission === 'string'
      ? NumberFormat.dynamicPercentage(commission)
      : String(commission);

  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} /> changed their commission from{' '}
      <strong>{formatCommission(changeBefore)}</strong> to <strong>{formatCommission(changeAfter)}</strong>
    </ActivityTitleText>
  );
}
