import {ScrollArea} from '@mantine/core';
import {type ReactNode, useContext, useEffect, useRef} from 'react';

import {debounce} from '@rockawayxlabs/observatory-utils';

import {NewsfeedContext} from './Newsfeed';
import {NewsfeedActivities} from './NewsfeedActivities';

export function NewsfeedBody({maxHeight}: {maxHeight?: number}) {
  if (maxHeight) {
    return (
      <NewsfeedBodyWrapperAutosize maxHeight={maxHeight}>
        <NewsfeedActivities />
      </NewsfeedBodyWrapperAutosize>
    );
  }

  return (
    <NewsfeedBodyWrapper>
      <NewsfeedActivities />
    </NewsfeedBodyWrapper>
  );
}

function NewsfeedBodyWrapperAutosize({maxHeight, children}: {maxHeight: number; children: ReactNode}) {
  const {totalCount, activities, loadMore} = useContext(NewsfeedContext);
  const viewportRef = useRef<HTMLDivElement>(null);

  const handleScrollPositionChange = ({y}: {x: number; y: number}) => {
    if (!totalCount || !viewportRef.current) {
      return;
    }
    const scrollHeight = viewportRef.current.scrollHeight - maxHeight;
    const bottomProximity = Math.abs(scrollHeight - y);
    if (bottomProximity <= 5 && totalCount > activities.length) {
      loadMore();
    }
  };

  return (
    <ScrollArea.Autosize
      mah={maxHeight}
      type="always"
      viewportRef={viewportRef}
      offsetScrollbars
      onScrollPositionChange={debounce(handleScrollPositionChange, 200)}
      styles={{
        thumb: {backgroundColor: 'var(--mantine-color-blue-6)'},
      }}
    >
      {children}
    </ScrollArea.Autosize>
  );
}

function NewsfeedBodyWrapper({children}: {children: ReactNode}) {
  const {loadMore} = useContext(NewsfeedContext);

  useEffect(() => {
    let timer: number;

    const handleScroll = () => {
      const loadTriggerPoint = document.body.offsetHeight - 100;
      const scrolledBy = window.innerHeight + window.scrollY;

      if (scrolledBy >= loadTriggerPoint) {
        loadMore();
      }
    };

    const debouncedHandleScroll = () => {
      clearTimeout(timer);
      timer = window.setTimeout(handleScroll, 150);
    };

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      clearTimeout(timer);
    };
  }, [loadMore]);

  return <>{children}</>;
}
