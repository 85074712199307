import {memo} from 'react';

import {ActivityType} from '@rockawayxlabs/observatory-database';

import type {NewsfeedActivity, NewsfeedZone} from '~/features/newsfeed/types';
import {getActivityLabel} from '~/features/newsfeed/utils/getActivityLabel';

import {ActivityTitleText} from './ActivityTitleText';
import {ChainSlowdown} from './ChainSlowdown';
import {GovernanceProposalVotingEnd} from './GovernanceProposalVotingEnd';
import {GovernanceProposalVotingStart} from './GovernanceProposalVotingStart';
import {GovernanceVoted} from './GovernanceVoted';
import {ValidatorActiveEnd} from './ValidatorActiveEnd';
import {ValidatorActiveStart} from './ValidatorActiveStart';
import {ValidatorCommissionChange} from './ValidatorCommissionChange';
import {ValidatorCreated} from './ValidatorCreated';
import {ValidatorDescriptionChange} from './ValidatorDescriptionChange';
import {ValidatorJailEnd} from './ValidatorJailEnd';
import {ValidatorJailStart} from './ValidatorJailStart';
import {ValidatorOutageEnd} from './ValidatorOutageEnd';
import {ValidatorOutageStart} from './ValidatorOutageStart';
import {ValidatorTokensChange} from './ValidatorTokensChange';
import {ValidatorTombstoned} from './ValidatorTombstoned';

export interface ActivityTitleProps extends Omit<NewsfeedActivity, 'type'> {
  zone: NewsfeedZone;
}

function Title({type, ...props}: ActivityTitleProps & {type: ActivityType}) {
  switch (type) {
    case ActivityType.CHAIN_SLOWDOWN:
      return <ChainSlowdown {...props} />;
    case ActivityType.GOVERNANCE_VOTED:
      return <GovernanceVoted {...props} />;
    case ActivityType.GOVERNANCE_PROPOSAL_VOTING_START:
      return <GovernanceProposalVotingStart {...props} />;
    case ActivityType.GOVERNANCE_PROPOSAL_VOTING_END:
      return <GovernanceProposalVotingEnd {...props} />;
    case ActivityType.VALIDATOR_ACTIVE_START:
      return <ValidatorActiveStart {...props} />;
    case ActivityType.VALIDATOR_ACTIVE_END:
      return <ValidatorActiveEnd {...props} />;
    case ActivityType.VALIDATOR_CREATED:
      return <ValidatorCreated {...props} />;
    case ActivityType.VALIDATOR_COMMISSION_CHANGE:
      return <ValidatorCommissionChange {...props} />;
    case ActivityType.VALIDATOR_DESCRIPTION_CHANGE:
      return <ValidatorDescriptionChange {...props} />;
    case ActivityType.VALIDATOR_JAIL_START:
      return <ValidatorJailStart {...props} />;
    case ActivityType.VALIDATOR_JAIL_END:
      return <ValidatorJailEnd {...props} />;
    case ActivityType.VALIDATOR_OUTAGE_START:
      return <ValidatorOutageStart {...props} />;
    case ActivityType.VALIDATOR_OUTAGE_END:
      return <ValidatorOutageEnd {...props} />;
    case ActivityType.VALIDATOR_TOKENS_CHANGE:
      return <ValidatorTokensChange {...props} />;
    case ActivityType.VALIDATOR_TOMBSTONED:
      return <ValidatorTombstoned {...props} />;
    default:
      return <ActivityTitleText>{getActivityLabel(type)}</ActivityTitleText>;
  }
}

export const ActivityTitle = memo(Title);
