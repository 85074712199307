import type {ActivityType} from '@rockawayxlabs/observatory-database';

const dictionary: Record<ActivityType, string> = {
  CHAIN_SLOWDOWN: 'Chain Slowdown',
  NODE_ADDED: 'Node Added',
  NODE_CHANGED: 'Node Changed',
  GOVERNANCE_VOTED: 'Governance Vote',
  GOVERNANCE_PROPOSAL_VOTING_START: 'Governance Voting Started',
  GOVERNANCE_PROPOSAL_VOTING_END: 'Governance Voting Ended',
  VALIDATOR_OUTAGE: 'Validator Outage',
  VALIDATOR_OUTAGE_START: 'Validator Outage Started',
  VALIDATOR_OUTAGE_END: 'Validator Outage Ended',
  VALIDATOR_ACTIVE_START: 'Validator Joined Active Set',
  VALIDATOR_ACTIVE_END: 'Validator Left Active Set',
  VALIDATOR_CREATED: 'Validator Created',
  VALIDATOR_COMMISSION_CHANGE: 'Validator Commission Changed',
  VALIDATOR_DESCRIPTION_CHANGE: 'Validator Description Changed',
  VALIDATOR_TOKENS_CHANGE: 'Validator Voting Power Changed',
  VALIDATOR_JAIL_START: 'Validator Jailed',
  VALIDATOR_JAIL_END: 'Validator Unjailed',
  VALIDATOR_TOMBSTONED: 'Validator Tombstoned',
};

export function getActivityLabel(type: ActivityType) {
  return dictionary[type] ?? `Unknown activity ${type}`;
}
