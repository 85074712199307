import {Alert, Group, Skeleton, Stack} from '@mantine/core';
import {IconAlertCircle, IconSearchOff} from '@tabler/icons-react';
import {useContext} from 'react';

import {NewsfeedContext} from './Newsfeed';

export function NewsfeedStatusFeedback() {
  const {status, cursor, totalCount} = useContext(NewsfeedContext);

  return (
    <Stack>
      {status === 'loading' && !cursor && (
        <>
          {Array.from({length: 10}, (_, i) => (
            <Group key={i} gap="xs" align="flex-start" mb="md">
              <Skeleton circle height={28} />
              <Stack gap="xs">
                <Skeleton width={100} height={16} radius="md" />
                <Skeleton width={200} height={14} radius="md" />
                <Skeleton width={80} height={12} radius="md" />
              </Stack>
            </Group>
          ))}
        </>
      )}

      {totalCount === 0 && (
        <Alert icon={<IconSearchOff />} title="No activity found">
          We are currently unable to fetch any related activity.
        </Alert>
      )}

      {status === 'error' && (
        <Alert icon={<IconAlertCircle />} color="red" title="Unexpected error occured">
          Newsfeed is currently unavailable. Please try again later.
        </Alert>
      )}
    </Stack>
  );
}
