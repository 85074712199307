import {Group, MultiSelect} from '@mantine/core';
import {IconTimelineEvent} from '@tabler/icons-react';
import {useContext} from 'react';

import type {ActivityType} from '@rockawayxlabs/observatory-database';

import {NewsfeedContext} from './Newsfeed';

export function NewsfeedHeader() {
  const {
    activityType: {value, data, onChange},
  } = useContext(NewsfeedContext);

  return (
    <Group align="flex-start" justify="space-between">
      <MultiSelect
        placeholder="All activity types"
        leftSection={<IconTimelineEvent size="1rem" />}
        size="xs"
        searchable
        clearable
        comboboxProps={{withinPortal: true}}
        w="100%"
        data={data}
        value={value}
        onChange={value => {
          onChange(value as ActivityType[]);
        }}
      />
    </Group>
  );
}
