import dayjs from 'dayjs';

import {formatDuration} from '~/utils/format';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';

export function ChainSlowdown({data}: ActivityTitleProps) {
  const formattedBlockTime = data?.blockTimeMs ? formatDuration(dayjs.duration(data.blockTimeMs, 'ms')) : 'long time';

  return (
    <ActivityTitleText>
      Chain produced a block after <strong>{formattedBlockTime}</strong>
    </ActivityTitleText>
  );
}
