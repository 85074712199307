import {Timeline, rem} from '@mantine/core';
import {useContext} from 'react';

import {NewsfeedContext} from '../Newsfeed';
import {ActivityIcon} from './ActivityIcon';
import {ActivityTitle} from './ActivityTitle';
import {NewsfeedItem} from './NewsfeedItem';

export function NewsfeedActivities() {
  const {activities, zone} = useContext(NewsfeedContext);

  return (
    <Timeline active={activities.length - 1} bulletSize={rem(28)} styles={{itemBody: {paddingTop: 5}}}>
      {activities.map(activity => (
        <Timeline.Item
          key={activity.uuid}
          title={<ActivityTitle {...activity} zone={zone} />}
          bullet={<ActivityIcon type={activity.type} size="1rem" />}
        >
          <NewsfeedItem activity={activity} zone={zone} />
        </Timeline.Item>
      ))}
    </Timeline>
  );
}
