import {formatOrdinal} from '@rockawayxlabs/observatory-utils';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function ValidatorCreated({data, validator}: ActivityTitleProps) {
  const {attempt} = data ?? {};
  const additionalInfo = attempt && attempt > 1 ? `(on the ${formatOrdinal(attempt)} attempt)` : '';

  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} /> was created {additionalInfo}
    </ActivityTitleText>
  );
}
