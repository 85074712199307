import {Anchor} from '@mantine/core';

import {LinkBuilder} from '@rockawayxlabs/observatory-utils';

import {Link} from '~/features/links';
import {NumberFormat} from '~/utils/NumberFormat';
import {formatUnderscoreCase} from '~/utils/format';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function GovernanceVoted({data, validator, zone}: ActivityTitleProps) {
  const proposalUrl = LinkBuilder.zone(zone.slug)
    .governance()
    .proposal(data?.proposalId ?? '')
    .toString();

  const vote = (data?.voteOptions ?? []).map(([type, weight], _, {length}) => {
    const label = formatUnderscoreCase(type);
    return length === 1 ? label : `${label} (${NumberFormat.percentage(weight)})`;
  });

  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} />
      <span> voted </span>
      <span style={{fontWeight: 600}}>"{vote}"</span>
      <span> on proposal </span>
      <Anchor component={Link} to={proposalUrl}>
        #{data?.proposalId}
      </Anchor>
    </ActivityTitleText>
  );
}
