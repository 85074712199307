import type {TablerIconsProps} from '@tabler/icons-react';
import {memo} from 'react';

import type {ActivityType} from '@rockawayxlabs/observatory-database';

import {getActivityIcon} from '~/features/newsfeed/utils/getActivityIcon';

interface ActivityIconProps extends TablerIconsProps {
  type: ActivityType;
}

function Icon({type, ...others}: ActivityIconProps) {
  const IconComponent = getActivityIcon(type);

  return <IconComponent {...others} />;
}

export const ActivityIcon = memo(Icon);
