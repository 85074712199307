import type {Prisma} from '@rockawayxlabs/observatory-database';

import {NumberFormat} from '~/utils/NumberFormat';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function ValidatorTokensChange({changeAfter, changeBefore, validator, zone}: ActivityTitleProps) {
  const formatTokens = (tokens: Prisma.JsonValue, tokenSymbol = '') =>
    typeof tokens === 'number' || typeof tokens === 'string'
      ? NumberFormat.tokenAmount(tokens, tokenSymbol)
      : String(tokens);

  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} /> stake changed from <strong>{formatTokens(changeBefore)}</strong> to{' '}
      <strong>{formatTokens(changeAfter, zone.tokenSymbol)}</strong>
    </ActivityTitleText>
  );
}
