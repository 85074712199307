import {Anchor} from '@mantine/core';

import {LinkBuilder} from '@rockawayxlabs/observatory-utils';

import {Link} from '~/features/links';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';

export function GovernanceProposalVotingStart({data, zone}: ActivityTitleProps) {
  const proposalUrl = LinkBuilder.zone(zone.slug)
    .governance()
    .proposal(data?.proposalId ?? '')
    .toString();

  return (
    <ActivityTitleText>
      <span>Voting period started on proposal </span>
      <Anchor component={Link} to={proposalUrl}>
        #{data?.proposalId}
      </Anchor>
    </ActivityTitleText>
  );
}
