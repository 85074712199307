import {Button, Stack, type StackProps} from '@mantine/core';
import {captureException, captureMessage} from '@sentry/remix';
import {IconLoader} from '@tabler/icons-react';
import {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {type ActivityType} from '@rockawayxlabs/observatory-database';
import {LinkBuilder} from '@rockawayxlabs/observatory-utils';

import type {NewsfeedActivityValidator, NewsfeedZone} from '~/features/newsfeed/types';
import {getActivityLabel} from '~/features/newsfeed/utils/getActivityLabel';
import {getAllowedActivityTypes} from '~/features/newsfeed/utils/getAllowedActivityTypes';
import {type DeserializedData} from '~/features/serialization/types';
import {deserialize} from '~/features/serialization/utils/deserialize';
import {useFeatureFlags} from '~/hooks/useFeatureFlags';
import type {loader as newsfeedLoader} from '~/routes/data.newsfeed';
import type {loader as validatorsLoader} from '~/routes/data.validators';

import {NewsfeedBody} from './NewsfeedBody';
import {NewsfeedHeader} from './NewsfeedHeader';
import {NewsfeedStatusFeedback} from './NewsfeedStatusFeedback';

interface NewsfeedContextValue {
  status: 'loading' | 'success' | 'error';
  activities: DeserializedData<typeof newsfeedLoader>['data'];
  totalCount: number | undefined;
  cursor: string | undefined;
  zone: NewsfeedZone;
  activityType: {
    value: ActivityType[];
    data: {value: ActivityType; label: string}[];
    onChange: (value: ActivityType[]) => void;
  };
  loadMore: () => void;
}

export const NewsfeedContext = createContext<NewsfeedContextValue>({} as NewsfeedContextValue);

export interface NewsfeedProviderProps extends StackProps {
  zone: NewsfeedZone;
  validatorId?: number;
  maxHeight?: number;
}

export function Newsfeed({zone, validatorId, maxHeight, ...others}: NewsfeedProviderProps) {
  const featureFlags = useFeatureFlags();

  const [status, setStatus] = useState<NewsfeedContextValue['status']>('loading');
  const [cursor, setCursor] = useState<string | undefined>();
  const [activities, setActivities] = useState<NewsfeedContextValue['activities']>([]);
  const [totalCount, setTotalCount] = useState<NewsfeedContextValue['totalCount']>();
  const [activityTypeValue, setActivityTypeValue] = useState<ActivityType[]>([]);
  const [fetchAllowed, setFetchAllowed] = useState(true);
  const [newsfeedValidators, setNewsfeedValidators] = useState<Map<number, NewsfeedActivityValidator | undefined>>(
    new Map()
  );
  const queryParamsRef = useRef({zoneId: zone.id, validatorId});

  const missingValidatorIds = useMemo(() => {
    return [...newsfeedValidators.entries()].flatMap(([id, validator]) => (validator ? [] : [id]));
  }, [newsfeedValidators]);

  const activityTypeData = useMemo(() => {
    return getAllowedActivityTypes({featureFlags, validatorOnly: !!validatorId}).map(type => ({
      value: type,
      label: getActivityLabel(type),
    }));
  }, [featureFlags, validatorId]);

  const handleActivityTypeChange = (value: ActivityType[]) => {
    setCursor(undefined);
    setActivities([]);
    setTotalCount(undefined);
    setActivityTypeValue(value);
    setFetchAllowed(true);
  };

  const handleLoadMore = useCallback(() => {
    if (!cursor) {
      return;
    }
    setFetchAllowed(true);
  }, [cursor]);

  useEffect(() => {
    // Reset state if zone or syncId changes
    const q = queryParamsRef.current;
    if (q.zoneId !== zone.id || q.validatorId !== validatorId) {
      queryParamsRef.current = {zoneId: zone.id, validatorId};
      setCursor(undefined);
      setActivities([]);
      setTotalCount(undefined);
      setFetchAllowed(true);
    }

    // Prevent fetch unless fetchAllowed is set to true (usually with scroll)
    // Otherwise this loops
    if (!fetchAllowed) {
      return;
    }

    const abortController = new AbortController();
    const newsfeedDataPath = LinkBuilder.data()
      .newsfeed({
        activityTypes: activityTypeValue,
        zoneId: zone.id,
        cursor,
        validatorId,
      })
      .toString();

    setStatus('loading');
    fetch(newsfeedDataPath, {signal: abortController.signal})
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch newsfeed data.');
        }
        return response.json();
      })
      .then(serializedData => {
        setFetchAllowed(false);
        setStatus('success');

        const {data, totalCount, nextCursor} = deserialize<DeserializedData<typeof newsfeedLoader>>(serializedData);
        setCursor(nextCursor);
        setTotalCount(totalCount);
        setActivities(prevActivities => [...prevActivities, ...data]);
      })
      .catch(error => {
        if (!(error instanceof Error)) {
          setFetchAllowed(false);
          setStatus('error');
          captureMessage('Fetching newsfeed data failed with non-Error response.', {extra: {error}});
          return;
        }
        if (error.name === 'AbortError') {
          return;
        }
        setFetchAllowed(false);
        setStatus('error');
        captureException(error);
      });

    return () => {
      abortController.abort();
    };
  }, [status, cursor, activityTypeValue, fetchAllowed, zone.id, validatorId]);

  // Find validator IDs to fetch validator data for
  useEffect(() => {
    const validatorIds = activities.map(activity => activity.validatorId).filter((id): id is number => !!id);
    setNewsfeedValidators(prevValidators => {
      const newValidators = new Map(prevValidators);
      validatorIds.forEach(id => {
        if (prevValidators.has(id)) {
          return;
        }
        newValidators.set(id, undefined);
      });
      return newValidators;
    });
  }, [activities]);

  // Fetch missing validator info
  useEffect(() => {
    if (missingValidatorIds.length === 0) {
      return;
    }

    const abortController = new AbortController();
    const validatorsDataPath = LinkBuilder.data()
      .validators({
        validatorIds: missingValidatorIds,
        zoneId: zone.id,
      })
      .toString();

    fetch(validatorsDataPath, {signal: abortController.signal})
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch validator data.');
        }
        return response.json();
      })
      .then(serializedData => {
        const validators = deserialize<DeserializedData<typeof validatorsLoader>>(serializedData);
        setNewsfeedValidators(prevValidators => {
          const newValidators = new Map(prevValidators);
          validators.forEach(validator => {
            newValidators.set(validator.validatorId, validator);
          });
          return newValidators;
        });
      })
      .catch(error => {
        if (!(error instanceof Error)) {
          captureMessage('Fetching validator data failed with non-Error response.', {extra: {error}});
          return;
        }
        if (error.name === 'AbortError') {
          return;
        }
        captureException(error);
      });

    return () => {
      abortController.abort();
    };
  }, [missingValidatorIds, zone.id]);

  return (
    <NewsfeedContext.Provider
      value={{
        status,
        activities: activities.map(activity => {
          if (!activity.validatorId) {
            return activity;
          }
          const validator = newsfeedValidators.get(activity.validatorId);
          return {...activity, validator};
        }),
        totalCount,
        cursor,
        zone,
        activityType: {
          value: activityTypeValue,
          data: activityTypeData,
          onChange: handleActivityTypeChange,
        },
        loadMore: handleLoadMore,
      }}
    >
      <Stack {...others}>
        <NewsfeedHeader />
        <NewsfeedStatusFeedback />
        <NewsfeedBody maxHeight={maxHeight} />
        {cursor && !maxHeight && (
          <Button
            size="xs"
            leftSection={<IconLoader size="1rem" />}
            loading={status === 'loading'}
            style={{alignSelf: 'center'}}
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        )}
      </Stack>
    </NewsfeedContext.Provider>
  );
}
