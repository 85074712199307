import type {StackProps} from '@mantine/core';
import {Skeleton, Stack, Text, Tooltip} from '@mantine/core';
import React from 'react';

import {DateTime} from '~/components/DateTime';
import type {NewsfeedActivity, NewsfeedZone} from '~/features/newsfeed/types';

import {NewsfeedChangelog} from './NewsfeedChangelog';

interface NewsfeedItemProps extends StackProps {
  activity: NewsfeedActivity;
  zone: NewsfeedZone;
}

function Item({activity, zone, ...others}: NewsfeedItemProps) {
  return (
    <Stack gap="xs" {...others}>
      <Tooltip
        label={
          <Stack gap={5}>
            <DateTime date={activity.timestamp} size="xs" />
            <Text size="xs">Block #{activity.blockHeight.toString()}</Text>
          </Stack>
        }
        position="bottom-start"
      >
        <Text component="div">
          <DateTime
            date={activity.timestamp}
            fallback={<Skeleton h={8} w={80} radius="sm" />}
            size="xs"
            c="dimmed"
            relative
          />
        </Text>
      </Tooltip>

      <NewsfeedChangelog before={activity.changeBefore ?? undefined} after={activity.changeAfter ?? undefined} />
    </Stack>
  );
}

export const NewsfeedItem = React.memo(Item);
