import dayjs from 'dayjs';

import {formatDuration} from '~/utils/format';

import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function ValidatorOutageEnd({data, validator}: ActivityTitleProps) {
  const formattedOutageTime = data?.outageMs ? formatDuration(dayjs.duration(data.outageMs, 'ms')) : 'long time';

  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} /> recovered from outage after <strong>{formattedOutageTime}</strong>
    </ActivityTitleText>
  );
}
