import type {ActivityTitleProps} from './ActivityTitle';
import {ActivityTitleText} from './ActivityTitleText';
import {NewsfeedValidator} from './NewsfeedValidator';

export function ValidatorOutageStart({validator}: ActivityTitleProps) {
  return (
    <ActivityTitleText>
      <NewsfeedValidator validator={validator} /> outage started
    </ActivityTitleText>
  );
}
