import {Text, type TextProps} from '@mantine/core';
import type {ReactNode} from 'react';

interface Props extends TextProps {
  children?: ReactNode;
}

export function ActivityTitleText({children, ...others}: Props) {
  return (
    <Text fw={500} size="sm" {...others}>
      {children}
    </Text>
  );
}
